import React, {useEffect} from 'react';
import { Route, Routes } from 'react-router';
// import { Dashboard } from '../Dashboard/Dashboard';
import { Navigation } from '../Dashboard/Nav/Navigation';
import { AppPage } from '../shared/AppPage';
import { TutorScreen } from './TutorScreen';
import { useAuth } from '../../helpers/useAuth';

const TitleContext = React.createContext('PhantomTutor');

export const DashboardScreen = () => {
    const session = useAuth();

    useEffect(() => {
        const twoHours = 1000 * 60 * 60 * 2;
        const minute = 1000 * 60 * 60;
        
        const interval = setInterval(() => {
            session();
        }, twoHours + minute);
        
        session();

        return () => clearInterval(interval);
    }, [session]);

    return (
        <TitleContext.Provider value={'PhantomTutor'}>
            <AppPage title={'PhantomTutor - Dashboard'}>
                <Navigation />
                <Routes>
                    <Route path='/' element={<TutorScreen />} />
                    <Route path='account/*' element={<div>Account crap</div>} />
                    <Route path='tutor' element={<TutorScreen />} />
                </Routes>
            </AppPage>
        </TitleContext.Provider>
    );
}