import React from 'react';
import miniboo from '../../images/miniboo.png';

export const Logo = ({className = ''}) => {
    return (
        <a 
        href='/' 
        className={`py-2 w-full md:w-36 flex justify-center ${className}`} 
        onClick={() => window.sessionStorage.removeItem('token')}>
            <img src={miniboo} alt='logo' className='md:w-full w-1/3' />
        </a>
    );
}