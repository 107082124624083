import React from 'react';
import { Helmet } from 'react-helmet';
import miniboo from '../../images/miniboo.ico';

export const AppPage = ({children, title, className = ''}) => {
    return (
        <main className={`bg-white flex flex-col md:flex-row ${className}`}>
            <Helmet>
                <title>{title}</title>
                <link rel='icon' href={miniboo} />
            </Helmet>
            {children}
        </main>
    );
}