import React from 'react';

const inputStyle = 'rounded p-2 border border-solid';
//const labelStyle = 'font-light mt-3 mb-3 w-full text-full text-white';

export const InputPair = ({label, type, onChange, onBlur, onFocus, value, className ='', darkStyle}) => (
    <>
        <input 
            placeholder={label} 
            value={value} 
            onBlur={onBlur} 
            onFocus={onFocus} 
            type={type} 
            className={`${inputStyle} ${className}${darkStyle ? ' text-black' : ''}`} 
            onChange={onChange} 
        />
    </>
);