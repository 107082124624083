import React from 'react';
import { ActiveLink } from '../../shared/ActiveLink';

/*
~~~~~~~~~~~~~~~~~~~~~~~~~
NavLink
~~~~~~~~~~~~~~~~~~~~~~~~~
The navlink component that 
basically is used in the navigation
bar. It just fixes positon on 
ActiveLink and that's about it
~~~~~~~~~~~~~~~~~~~~~~~~~
*/

export const NavLink = ({link, label}) => (
    <ActiveLink link={link} label={label} position='left' />
);