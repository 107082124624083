import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import useInput from '../../helpers/useInput';
import { InputPair } from '../shared/InputPair';
//import { Logo } from '../shared/Logo';
import logo from '../../images/phantom-tutor-logo.svg';

export const Register = ({setUserInfo, userInfo}) => {
    const [email, updateEmail] = useInput('');
    const [phone, updatePhone] = useInput('');
    const [username, updateUsername] = useInput('');
    const [password, updatePassword] = useInput('');
    const [confirm, updateConfirm] = useInput('');
    
    const [line1, updateLine1] = useInput('');
    const [line2, updateLine2] = useInput('');
    const [city, updateCity] = useInput('');
    const [state, updateState] = useInput('');
    const [country, updateCountry] = useInput('US');
    const [postal_code, updatePostalCode] = useInput('');
    

    const [validEmail, setValidEmail] = useState(true);
    const [validPhone, setValidPhone] = useState(true);
    const [validUsername, setValidUsername] = useState(true);
    const [validPassword, setValidPassword] = useState(true);

    const [allowNext, setAllowNext] = useState(false);

    const navigate = useNavigate();

    useEffect(() => {
        if (email === '' || 
            password === '' || 
            username === '' || 
            phone === '' ||
            confirm === '' ||
            line1 === '' ||
            city === '' ||
            state === '' ||
            country === '' ||
            postal_code === '' 
        ) {
            setAllowNext(false);   
        }

        
        else {
            setAllowNext(
                validEmail && 
                validPassword && 
                validUsername && 
                validPhone
            );
        }
        
    }, [
        email, 
        username, 
        password, 
        phone, 
        validEmail, 
        validPassword, 
        validUsername, 
        validPhone, 
        confirm, 
        city,
        state,
        country,
        postal_code,
        line1
    ]);


    useEffect(() => {
        if (password && confirm) 
            setValidPassword(password === confirm);
    }, [password, confirm]);
    

    async function verifyEmail (e) {
        if (email !== '') {
            const valid = await axios({
                url : '/api/register/verify-email',
                method : 'POST',
                data : {
                    email
                }
            });
            setValidEmail(!valid.data.valid);
        }
    }


    async function verifyUsername (e) {
        if (username !== '') {
            const valid = await axios({
                url : '/api/register/verify-username',
                method : 'POST',
                data : {
                    username
                }
            });
            setValidUsername(!valid.data.valid);
        }
    }


    function verifyPhone (e) {
        if (phone !== '') {
            setValidPhone(phone.length === 10);
        }
    }


    function verifyPassword (e) {
        setValidPassword(password === confirm);
    }


/*
    Page Navigation functions
*/

    async function nextPage(e) {
        if (allowNext) {
            setUserInfo({
                email,
                password,
                username,
                phone,
                address : {
                    city,
                    country,
                    state,
                    line1,
                    line2,
                    postal_code
                }
            });
            
            await axios({
                url : '/api/stripe/create-customer',
                method : 'POST',
                data : {
                    email,
                    phone,
                    username,
                    address : {
                        city,
                        country,
                        state,
                        line1,
                        line2,
                        postal_code
                    }
                }
            });
            
            navigate('/register/payment');
        }
    }


    function previousPage(e) {
        navigate('/');
    }


    return (
        <section className='bg-darkBg rounded lg:p-0 h-full w-full flex flex-col items-center'>
            <img src={logo} alt='logo' className='w-9/12 max-w-96 py-10'/>

            <div className='w-full h-full px-10 flex flex-col items-center justify-center'>
                <h1 className='text-4xl text-white font-bold w-full'>
                    Sign Up
                </h1>

                <InputPair
                    value={email || userInfo.email}
                    type='email'
                    label='Email'
                    onChange={updateEmail}
                    onBlur={verifyEmail}
                    className={`mt-10 w-full${!validEmail ? ' border-red-500' : ''}`}
                />
                {
                    !validEmail &&
                        <p className="invalid-input">
                            Error: This email is in use
                        </p>
                }

                <InputPair
                    value={phone || userInfo.phone}
                    type='phone'
                    label='Phone'
                    onChange={updatePhone}
                    onBlur={verifyPhone}
                    className={`mt-8 w-full${!validPhone ? ' border-red-500' : ''}`} 
                />
                {
                    !validPhone && 
                        <p className='invalid-input'>
                            Error: This phone number is invalid
                        </p>
                }
                
                <InputPair
                    value={username || userInfo.username}
                    type='text'
                    label='Username'
                    onChange={updateUsername}
                    onBlur={verifyUsername}
                    className={`mt-8 w-full${!validUsername ? ' border-red-500' : ''}`}
                />
                {
                    !validUsername &&
                        <p className="invalid-input">
                            Error: This username is in use
                        </p>
                }

                <InputPair
                    value={password || userInfo.password}
                    type='password'
                    label='Password'
                    onChange={updatePassword}
                    className='w-full mt-8'
                />
            
                <InputPair
                    value={confirm || userInfo.password}
                    type='password'
                    label='Confirm Password'
                    onChange={updateConfirm}
                    onBlur={verifyPassword}
                    className={`mt-8 w-full${!validPassword ? ' border-red-500' : ''}`}
                />
                {
                    !validPassword && 
                        <p className="invalid-input">
                            Error: Passwords don't match
                        </p>
                }

                <InputPair 
                    value={line1}
                    type='text'
                    label='Address Line 1'
                    onChange={updateLine1}
                    className={'mt-8 w-full'}
                    onBlur={() => console.log('yo')}
                />

                <InputPair 
                    value={line2}
                    type='text'
                    label='Address Line 2'
                    onChange={updateLine2}
                    className={'mt-8 w-full'}
                    onBlur={() => console.log('yo')}
                />

                <span className='flex flex-row w-full justify-evenly'>
                    <InputPair 
                        value={city}
                        type='text'
                        label='City'
                        onChange={updateCity}
                        className={'mt-8 w-1/3'}
                        onBlur={() => console.log('yo')}
                    />

                    <InputPair 
                        value={state}
                        type='text'
                        label='State'
                        onChange={updateState}
                        className={'mt-8 w-1/6'}
                        onBlur={() => console.log('yo')}
                    />

                    <InputPair 
                        value={postal_code}
                        type='text'
                        label='Zip'
                        onChange={updatePostalCode}
                        className={'mt-8 w-1/3'}
                        onBlur={() => console.log('yo')}
                    />

                </span>

                <span className='flex flex-row mt-5 mb-5'>
                    <button
                        onClick={nextPage}
                        className={`${allowNext ? 'button' : 'inactive-button'} mx-3 transition-colors ease-in duration-100`}
                    >
                        Continue Registration
                    </button>

                    <button
                        onClick={previousPage}
                        className='cancel-button bg-gray-800'
                    >
                        Cancel
                    </button>
                </span>
            </div>

        </section>
    );
}
