import React from 'react';
import { Helmet } from 'react-helmet';
import miniboo from '../../images/miniboo.ico';

export const Page = ({children, title, className=''}) => (
    <>
    <Helmet>
        <title>{title}</title>
        <link rel='icon' href={miniboo} />
    </Helmet>

    <main className={`min-w-full overflow-x-hidden min-h-screen ${className}`}>
        {children}
    </main>
    </>
);