import React, { useEffect, useRef } from 'react';

export const MessageList = ({messages}) => {
    const endOfMessagesRef = useRef(null);

    const scrollToBottom = () => {
        endOfMessagesRef.current?.scrollIntoView({ behavior: "smooth" })
    }

    useEffect(() => {
        scrollToBottom();
    }, [messages]);

    return (
        <section className="h-full mt-14 md:mt-0 overflow-y-scroll px-2 py-1">
            {
                messages.map((msg) => 
                    <div className={`w-full flex flex-row my-5 ${msg.sent ? 'justify-end' : 'justify-start'}`}>
                        <p className={`rounded-3xl px-4 py-2 ${msg.sent ? "bg-mainColor text-white" : "bg-questionAnswer-a"}`}>
                            {msg.message}
                        </p>
                    </div>
                )
            }
            <div ref={endOfMessagesRef} />
        </section>
    );
}
