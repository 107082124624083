import React, {useState} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperPlane } from '@fortawesome/free-solid-svg-icons';

export const QuestionInput = ({isWaiting, setIsWaiting, socket}) => {
    const [question, setQuestion] = useState('');

    async function handleSend() {
        setIsWaiting(true);
        socket.emit("message-sent", {
            userId : parseInt(sessionStorage.getItem('userId')),
            message : question,
            auth : window.sessionStorage.getItem('token')
        });
        setQuestion('');
    }

    function handleEnter(e) {
        if (e.key === 'Enter' && question) handleSend();
    }

    function handleText(e) {
        setQuestion(e.target.value.replace('\n', ''));
    }
    return (
        <span className="bg-white w-full px-5 py-2 flex flex-row">
            <span className='w-full bg-gray-50 rounded-full flex px-4 items-center'>
                <textarea 
                    value={question} 
                    className="w-full bg-transparent h-1/2 border-none outline-none" 
                    onChange={handleText} 
                    onKeyDown={handleEnter} 
                    placeholder="Ask a question..." />

                <button 
                    className={`text-2xl p-2 ml-2  ${ isWaiting ? 'text-gray-500 cursor-not-allowed' : 'text-mainColor'}`} 
                    onClick={() =>  {if (!isWaiting) handleSend()}}>
                        <FontAwesomeIcon icon={faPaperPlane} />
                </button>
            </span>
        </span>
    );
}