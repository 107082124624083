import React, {useState} from 'react';
import { NavLink } from './NavLink.jsx';
import logo from '../../../images/phantom-tutor-logo.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faClose, faHome } from '@fortawesome/free-solid-svg-icons';

export const Navigation = () => {
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

    const toggle = () => {
        setMobileMenuOpen((prev) => !prev);
    }

    return (
        <nav className='h-14 justify-between md:justify-start md:h-screen w-screen md:w-64 bg-darkBg flex md:flex-col md:items-center px-5 md:px-0 z-50 md:z-0 fixed md:relative'>
            <img src={logo} alt='logo' className='py-2 ' />
            <br/><br/>
            <div className='text-white w-full hidden md:flex flex-col items-center space-y-5'>
                <NavLink label='Home' link='/dashboard' />
                {/*
                <NavLink label='Tutor' link='/dashboard/tutor' />
                */}
            </div>
            <span className="text-white text-2xl flex items-center ml-10 md:hidden">
                <button onClick={() => toggle()}>
                    <FontAwesomeIcon icon={faBars} />
                </button>
            </span>
            <div className={`fixed h-screen w-1/2 bg-darkBg text-white left-full px-5 py-3 top-0 z-20 transition transform delay-200 ${mobileMenuOpen ? '-translate-x-full' : 'translate-x-0'}`}>
                    <span className="text-2xl" onClick={() => toggle()}>
                        <FontAwesomeIcon 
                            icon={faClose}
                        />
                    </span>

                    <div className="mt-8">
                        <span className="text-xl">
                            <FontAwesomeIcon icon={faHome} />
                            <label className="ml-5">
                                Home
                            </label>
                        </span>
                    </div>
                </div>
        </nav>
    );
}