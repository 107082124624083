import React, { useState } from "react";
import { Routes, Route } from "react-router";
import { Page } from "../shared/Page";
import { ProductSelection } from "../Signup/ProductSelection";
import { Register } from "../Signup/Register";
import useInput from "../../helpers/useInput";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { SideContent } from "../shared/SideContent";

export const SignupScreen = () => {
    const [userInfo, setUserInfo] = useState({});
    const [selectedProduct, updateSelectedProduct] = useInput();
    const stripe = loadStripe('pk_live_51KHjEjEysn6JLsLOLKil5taopOy5koQWtIVMhVeYih5wsSzj8t0Wfs4o05jT9WCluj6dxUbOwgsxJhlIiJvDAWwK00DuIjyv7Z')

    return (
        <Page 
            title='PhantomTutor - Sign Up' 
            className="bg-darkBg min-h-screen flex flex-col justify-center items-center lg:grid lg:grid-cols-3">
            <Elements stripe={stripe}>
            <Routes>
                <Route 
                    path='/' 
                    element={
                        <Register 
                            userInfo={userInfo} 
                            setUserInfo={setUserInfo} 
                        />
                    } 
                />
                <Route 
                    path='payment' 
                    element={
                        <ProductSelection 
                            product={selectedProduct}
                            updateSelectedProduct={updateSelectedProduct} 
                            userInfo={userInfo}
                        />
                    } 
                />
            </Routes>
            </Elements>
            <SideContent
                image='https://images.unsplash.com/photo-1651870364199-fc5f9f46ac85?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2670&q=80'
                className={'h-screen'}
            />
        </Page>
    );
}