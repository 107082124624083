import React, { useEffect, useState} from "react";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import axios from "axios";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";

export const ProductSelection = ({product, updateSelectedProduct, userInfo}) => {
    const navigate = useNavigate();
    const stripe = useStripe();
    const elem = useElements();

    const [ prices, setPrices ] = useState();
    const [ priceBoxes, setPriceBoxes] = useState((<div className='animate-pulse h-12 w-12' />));
    const [ showCard, setShowCard ] = useState(false);
    const [ clientSecret, setClientSecret ] = useState('');
    const [ , setSubId ] = useState('');

    useEffect(() => {
        async function fetchPrices() {
            const prices = await axios({
                url : '/api/stripe/prices',
                method : 'GET'
            });
            console.log(prices.data.priceId.data)
            setPrices(prices.data.priceId.data)
        }
        fetchPrices();
    }, []);

    useEffect(() => {
        if (prices) {
            const boxes = prices.map((price) => (
                <div className='bg-white p-8 w-full max-w-lg flex flex-col space-y-5 items-center rounded-md' key={price.lookup_key}>
                    <h3 className='text-2xl'>
                        PhantomTutor Beta
                    </h3>
                    <p className='text-lg italic'>
                        ${price.unit_amount / 100} / month
                    </p>
                    <p className='text-left'>
                        The basic tutoring AI. Get your
                        questions answered by text and 
                        app. 
                        <br /><br />
                        Signing up now locks in this
                        price for all future upgrades at the
                        premium level.
                        <br />
                        <i>Currently in beta!</i>
                    </p>
                    <button 
                        onClick={(e) => selectProduct(price.id)}
                        className='px-5 py-2 cursor-pointer bg-mainColor rounded-full text-white'
                    >
                        Select
                    </button>
                </div>
            ));
            setPriceBoxes(boxes);
        }
    }, [prices]);

    async function selectProduct(priceId) {
        const product = await axios({
            url : '/api/stripe/create-subscription',
            method : 'POST',
            data : {
                priceId,
                username : userInfo.username
            }
        });
        

        if (!!product.data.subscriptionId) {
            setSubId(product.data.subscriptionId);
            setClientSecret(product.data.clientSecret);
            setShowCard(true);
        }
    }
    
    async function register (e) {
        const cardElement = elem.getElement(CardElement);
        const {paymentIntent} = await stripe.confirmCardPayment(clientSecret, {
            payment_method: {
                card : cardElement
            }
        });

        if (paymentIntent && paymentIntent.status === 'succeeded') {
            const registered = await axios({
                url: '/api/register',
                method: 'POST',
                data : {
                    username : userInfo.username,
                    password : userInfo.password,
                    email : userInfo.email,
                    phone : userInfo.phone,
                    product : 'basic'
                }
            });

            if(!!registered) navigate('/login');
        }
    }

    return (
        <section className='bg-darkBg w-full p-5 h-full flex flex-col justify-center items-center'>
            <h2 className='text-3xl mb-5 font-semibold text-white'>
                Purchase
            </h2>
            
            <section>
                {priceBoxes}
            </section>

            { showCard &&
            (<div className='bg-white rounded-md p-7 flex flex-col w-full mt-5 transition-opacity duration-200 ease-in opacity-100'>
                
                <CardElement className='mb-5 text-white' />
                
                <button 
                    className='text-white px-5 py-3 bg-mainColor rounded-full justify-center'
                    onClick={register}
                >
                        Confirm Subscription
                </button>
            </div>)
            }

            <span className='flex flex-row w-full justify-evenly'>
                <Link 
                    to='/'
                    className='rounded-full py-2 px-5 mt-5 bg-gray-800 w-max text-white text-center'
                >
                    Cancel
                </Link>
            </span>
            <p className='text-white mt-5'>
                By continuing you agree to our{' '}
                <a href='https://phantomtutor.com/legal' className='underline' target='_blank' rel='noreferrer'>
                    Terms & Conditions
                </a>
            </p>
        </section>
    );
}
