import './App.css';
import {Routes, Route} from 'react-router-dom';
import { LoginScreen } from './components/screens/LoginScreen';
import { SignupScreen } from './components/screens/SignupScreen';
import { DashboardScreen } from './components/screens/DashboardScreen';
import { LandingScreen } from './components/screens/LandingScreen';
import { AuthRoute } from './components/shared/AuthRoute';
import { atom } from 'recoil';

export const authorizedUser = atom({
  key : 'authorizedUser',
  default: false
});

function App() {
  return (
      <Routes>
        <Route path='/' element={<LandingScreen />} />
        <Route path='login' element={<LoginScreen />} />
        <Route path='register/*' element={<SignupScreen />} />
        <Route path='dashboard/*' 
          element={
            <AuthRoute>
              <DashboardScreen />
            </AuthRoute>} 
        />
      </Routes>
  );
}

export default App;
