import React, { useState } from "react";
import { InputPair } from "../shared/InputPair";
import axios from 'axios';
import { useNavigate } from "react-router";
import logo from '../../images/phantom-tutor-logo.svg';
import { Link } from "react-router-dom";

export const LoginBox = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [loginValid, setLoginValid] = useState(true);
    const navigate = useNavigate();


    async function handleSubmit () {
        const data = await axios('/api/login', {
            method : 'POST',
            data : {
                username,
                password
            }
        });
        if (data.data.user) {
            window.sessionStorage.setItem('userId', data.data.user);
            navigate('/dashboard');
        }
        else setLoginValid(false);
    }

    return (
        <section className="flex flex-col items-center justify-center min-h-screen h-full w-full p-5 bg-darkBg">
            <img src={logo} alt='logo' className='mb-5'/>

            <div className='flex flex-col justify-center items-center h-full w-full'>
                <h1 className='text-center text-4xl font-bold text-white w-full flex'>
                    Welcome Back!
                </h1>
                
                { !loginValid &&  
                    <div className='text-red-600 italic mt-2'>
                        Invalid username or password
                    </div>
                }

                <InputPair label='Username' type='text' onChange={(e) => setUsername(e.target.value)} className='w-full my-10' />
                <InputPair label='Password' type='password' onChange={(e) => setPassword(e.target.value)} className='w-full' />

                <button 
                    onClick={() => handleSubmit()} 
                    className='rounded-full py-2 px-5 mt-5 bg-mainColor w-max text-white text-center'>
                    Log In
                </button>
                <p className='text-white mt-5'>
                    Don't have an account? {' '}
                    <Link to='/register' className='underline'>
                        Sign up
                    </Link>
                </p>
            </div>
        </section>
    );
}