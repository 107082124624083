import React from 'react';

export const SideContent = ({ image, className }) => {
    return( 
        <section className='col-span-2 h-full w-full lg:block hidden bg-white'>
            <img 
                src={image} 
                alt='side filler' 
                className={`w-full min-h-screen bg-cover ${className}`}
            />
        </section>
    );
};