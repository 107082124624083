import axios from 'axios';
import React, {useState, useEffect} from 'react';
import { useNavigate } from 'react-router';
import io from 'socket.io-client';
import { MessageList } from '../Tutor/MessageList';
import { QuestionInput } from '../Tutor/QuestionInput';

export const TutorScreen = () => {
    const [messages, setMessages] = useState([]);
    const [socket, setSocket] = useState();
    const [isWaiting, setIsWaiting ] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        axios('/api/app/message', {
            method : 'GET',
        }).then((response) => {
            setMessages(response.data.messageList);
        });
    }, [navigate]);

    useEffect(() => {
        const newSocket = io();

        setSocket(newSocket)

        newSocket.on('message-response', (response) => {
            setMessages(response.messageList);
            setIsWaiting(response.waiting);
        });

        newSocket.on('unauthorized', (response) => {
            navigate('/');
        });
    }, [navigate])

    return (
        <div className="w-full h-screen max-w-96 flex flex-col space-between">
            <MessageList messages={messages} />
            <QuestionInput
                isWaiting={isWaiting}
                setIsWaiting={setIsWaiting}
                socket={socket}
            />
        </div>
    );
}