import React from 'react';
import { Link } from 'react-router-dom';
import { Logo } from '../shared/Logo';
import { Page } from '../shared/Page';
import { SideContent } from '../shared/SideContent';

export const LandingScreen = () => {

    return (
        <Page title='PhantomTutor' className='text-white flex justify-center items-center lg:grid lg:grid-cols-3 bg-black'>
            <section className='flex flex-col justify-center items-center bg-darkBg h-screen lg:h-full py-4 px-2 w-full'>
                <h1 className='text-4xl md:text-5xl text-center font-semibold'> 
                    PhantomTutor
                </h1>

                <Logo className='mb-5 mt-5' />

                <div className='text-white w-full flex flex-col justify-evenly h-48 items-center'>
                    <Link to='/register' className='text-center px-5 py-3 rounded-full bg-mainColor w-44'>Register</Link>
                    <Link to='/login' className='text-center px-5 py-3 rounded-full bg-gray-50 text-black w-44'>Log In</Link>
                </div>
            </section>

            <SideContent 
                image="https://images.unsplash.com/photo-1626553683558-dd8dc97e40a4?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1287&q=80"
                className={'h-screen'}
            />
        </Page>)

}