import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome } from '@fortawesome/free-solid-svg-icons';

/*
~~~~~~~~~~~~~~~~~~~~~~~~~
ActiveLink
~~~~~~~~~~~~~~~~~~~~~~~~~
A link component that will highlight if
the link it points to is the current page
~~~~~~~~~~~~~~~~~~~~~~~~~
*/


export const ActiveLink = ({link, label, position}) => {
    const whereAmI = useLocation();

    function onThisPage() {
        if (link.includes('class')) {
            return whereAmI.pathname.includes(link);
        } else {
            return whereAmI.pathname === link;
        }
    }

    let linkStyle = 'w-full mt-2 hover:text-mainColor transition ease-in font-semibold text-2xl px-6'
    switch (position) {
        case 'top':
            linkStyle += ' border-t-2';
            break;
        case 'bottom':
            linkStyle += ' border-b-2';
            break;
        case 'left':
            linkStyle += ' border-l-2';
            break;
        case 'right':
            linkStyle += ' border-r-2';
            break;
        default:
            break;
    }

    return (
        <Link 
            to={link} 
            className={`${linkStyle} ${onThisPage() ? 'border-mainColor' : 'border-transparent'}`}
        >
            <span>
                <FontAwesomeIcon icon={faHome} />
                <label className='ml-5'>{label}</label>
            </span>
        </Link>
    );
}