import React from "react";
import { LoginBox } from "../Login/LoginBox";
import { Page } from "../shared/Page";
import { SideContent } from "../shared/SideContent";

export const LoginScreen = () => {
    return (
        <Page 
            title='PhantomTutor - Log In'
            className="bg-darkBg h-full flex flex-col justify-center items-center lg:grid lg:grid-cols-3"> 
            <LoginBox />
            <SideContent 
                image="https://images.unsplash.com/photo-1630873292791-66937fdabdb8?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1287&q=80"
                className={'h-screen'}
            />
        </Page>
    );
}